import {createAction, props} from '@ngrx/store';

// Form init
export const initUser = createAction(
  'Init user',
  props<{
    data: {};
  }>()
);

