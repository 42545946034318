import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { HomeComponent } from './home/home.component';

// Angular material module
import {MatButtonModule} from '@angular/material/button';
import { ModalSaveEmailComponent } from './home/modal-save-email/modal-save-email.component';
import {ComponentsModule} from "../components/components.module";
import {ReactiveFormsModule} from "@angular/forms";
import {NgbAlertModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    HomeComponent,
    ModalSaveEmailComponent,
  ],
    imports: [
        CommonModule,
        PagesRoutingModule,
        MatButtonModule,
        ComponentsModule,
        ReactiveFormsModule,
        NgbAlertModule,
    ]
})
export class PagesModule { }
