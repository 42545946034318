import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {APP_NAME} from "../constants/inedx";

const routes: Routes = [
  {
    path : '', component : HomeComponent,
    title: `${APP_NAME} : Accueil`,
    data: {
      description: "Découvrez mon service d'aménagement intérieur 3D en optant pour un design écoresponsable. Nous repensons ensemble votre espace en harmonie avec la planète grâce à des solutions sur mesure."
    }
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes),],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
