<footer>
  <div class="footer-sentence">
    Le site web est en cours de développement, en attendant, prenons contact ici
  </div>
  <div class="container-logo">
    <!-- Mail   -->
    <a href="mailto:contact@inspiration-colibri.com" class="no-link box-shadow" target="_blank">
      <img class="logo" alt="mail" src="/assets/images/icons/footer/picto_email.png">
    </a>
    <!-- Instagram   -->
    <a class="no-link box-shadow" href="https://www.instagram.com/inspiration.colibri/" class="no-link"
       target="_blank">
      <img class="logo" alt="instagram" src="assets/images/icons/footer/picto_instagram.png">
    </a>
    <!-- Facebook   -->
    <a class="no-link box-shadow" href="https://www.facebook.com/profile.php?id=61561480942938" class="no-link"
       target="_blank">
      <img class="logo" src="assets/images/icons/footer/picto_facbook.png">
    </a>
    <!-- Linkedin   -->
    <a href="https://www.linkedin.com/in/justinemuller-architecture-interieure-ecoresponsable"
       class="no-link box-shadow" target="_blank">
      <img class="logo" alt="linkedin" src="assets/images/icons/footer/picto_linkedin.png">
    </a>
  </div>
</footer>
