import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LayoutModule} from "./layout/layout.module";
import {SharedModule}  from "./shared/shared.module"
@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    LayoutModule,
    SharedModule
  ],
    exports: [
        LayoutModule,
        SharedModule
    ]
})
export class ComponentsModule { }
