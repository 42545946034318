import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Data, NavigationEnd, Router} from "@angular/router";
import {MetaService} from "../../../service/meta/meta.service";
import {filter, map, mergeMap, tap} from "rxjs/operators";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private metaService: MetaService) { }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        tap(({description}: Data) => {
          this.metaService.updateDescription(description);

        })
      ).subscribe();
  }

}
