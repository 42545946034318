<div *ngIf="!activeModal" class="home">
  <!-- Title  -->
  <section class="home-title iclbr-primary">
    <h1>
      architecte d'intérieur
      <br class="onlyMobile">
      éco-responsable
    </h1>
  </section>
  <!-- Coming soon sentence -->
  <section class="home-coming-soon">
    <h2>
      - arrive prochainement -
    </h2>
  </section>
  <!-- Main banner -->
  <section class="home-ban">
    <!-- Image Photo -->
    <div class="home-ban-header">
      <img alt="Justine Muller" class="home-ban-header-photo" src="assets/images/others/justine_muller.png">
    </div>
    <!-- Details -->
    <article class="home-ban-details">
      <!-- Title -->
      <div class="home-ban-details-title">
        <h3>
          Je modélise votre projet en 3D sous vos yeux et nous le construisons ensemble !
        </h3>
      </div>
      <!-- Block -->
      <div class="home-ban-details-block">
        <!-- Block -->
        <div class="home-ban-details-block-sentence">
          <p class="sentence">
            Je m'appelle Justine et je suis <span class="architecte iclbr-primary"> architecte d'intérieur éco-responsable.</span>
            <br>
            J'aide les personnes en quête d'un nouvel aménagement intérieur en les accompagnant dans la modélisation 3D
            de leur projet pour leur apporter une vision cohérente de leurs idées et leur proposer des gammes de
            produits éco-responsables pour un intérieur plus sain et respectueux de leurs valeurs et de la planète !
            <br>
            Inscrivez-vous à la Newsletter dès maintenant pour ne pas manquer les dernières actualités, services et
            produits chez Inspiration.Colibri ! Rassurez-vous, je ne pratique pas le spam 🌏😉
          </p>
        </div>
      </div>
    </article>
    <!-- Image Mockup -->
    <div class="home-ban-mockup">
      <img class="mockup" alt="mockup tablette" src="assets/images/others/Mockup%20Tablette.png">
    </div>
  </section>
  <!-- Button -->
  <section class="home-btn">
    <button class="iclbr-primary-background" (click)="openModal()">
      Je veux recevoir la Newsletter
    </button>
    <button class="iclbr-primary-background">
      <a href="https://forms.gle/167JYMFPSsaYuoqQ7" target="_blank">
        Mon RDV offert ici !
      </a>
    </button>
  </section>
</div>
<app-modal-save-email *ngIf="activeModal"
                      (close)="closeModal()"
></app-modal-save-email>
