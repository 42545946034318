import {ActionReducer, createReducer, MetaReducer, on} from '@ngrx/store';
import {IState} from '../models/ngrx/IState';
import {IRootState} from '../models/ngrx/IRootState';

// Reducers
import * as user from './reducers/user';

const initialState: IRootState = {
  user : user.reducers.userState
}

function log(reducer: ActionReducer<IState>): ActionReducer<IState> {
  return (state, action) => {
    const currentState = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('Etat précédent : ', state);
    console.log('Action : ', action);
    console.log('Etat suivant : ', currentState);
    console.groupEnd();
    return currentState;
  };
}

export const metaReducers: MetaReducer[] = [log];

export const rootReducer = createReducer<IRootState>(
  initialState
)
