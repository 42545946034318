import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./styles/home.mobile.scss', 'styles/home.desktop.scss']
})

export class HomeComponent implements OnInit {
  activeModal = false;
  constructor() { }

  ngOnInit(): void {}

  openModal(): void {
    this.activeModal = true;
  }
  closeModal(): void {
    this.activeModal = false;
  }

}
