import {inject, Injectable, Injector} from "@angular/core";
import { Firestore, collectionData, collection, addDoc } from '@angular/fire/firestore';
import {IEmail} from "../../models/firebase/IEmail";


@Injectable({
  providedIn: 'root',
})

export class EmailService {
  private collectionName = 'iclbr-email';
  private firestore: Firestore = inject(Firestore);
  private collection = collection(this.firestore, this.collectionName);

  async add(email : string) : Promise<boolean> {
    const emails = await this.getAll();
    const item = emails.find((item) => item.email.toLowerCase() === email.toLowerCase());
    if(!item){
      const createdOn = new Date().getTime();
      return await new Promise((resolve, reject) => {
        return addDoc(this.collection, {email, createdOn})
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            resolve(false);
          })
      })
    }else{
      return false
    }
  }

  async getAll() : Promise<IEmail[]> {
    return await new Promise((resolve, reject) => {
      return collectionData(this.collection)
        .subscribe((firebaseData) => {
          const emails = firebaseData as IEmail[];
          resolve(emails)
        }, error => resolve([]))
    })
  }

}
