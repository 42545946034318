<div class="overlay">
  <!-- Modal  -->
  <div class="iclbr-modal">
    <!-- Header - image  -->
    <div class="iclbr-modal-header">
      <h3 class="iclbr-modal-header-title">
        nous misons sur la transparence des données :
        <br>
        les règles  de collecte et utilisation de votre adresse email
      </h3>
    </div>
    <!-- Body - Articles  -->
    <div class="iclbr-modal-body">
      <article class="article">
        <!--   Cher(e)     -->
        <h4 class="article-greetings">
          Cher(e) utilisateur(trice) d’Inspiration.Colibri,
        </h4>
        <p class="article-text">
          Je suis ravie de vous accueillir sur Inspiration.Colibri,
          le site internet en cours de développement de mon agence en ligne d’architecture intérieure écoresponsable.
          Mon équipe de webdesigner et moi-même souhaitons vous informer de notre politique concernant la collecte et l’utilisation de vos adresses email.
          <br>
          <br>
          Vous êtes informé que ces informations seront enregistrées dans un fichier informatisé conformément à notre Politique de confidentialité.
          <br>
          <br>
          En renseignant votre adresse email et en validant votre inscription, vous demandez expressément à recevoir la newsletter sur les évènements, services et produits d’Inspiration.Colibri.
          Vous êtes informé que vous pouvez vous désinscrire à tout moment de la newsletter en cliquant sur le lien de désinscription prévu à cet effet dans les mails qui vous seront adressés.
        </p>
      </article>
    </div>
    <!-- Footer - Form  -->
    <div class="iclbr-modal-footer">
      <!-- Form + Buttons  -->
      <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <!-- Inputs  -->
        <div class="form-input">
          <input type="text"
                 class="iclbr-input"
                 placeholder="exemple@email.com"
                 formControlName="email"
          >
          <!-- Error EMAIL  -->
          <div *ngIf="form.controls['email'].invalid
                      && (form.controls['email'].dirty
                      || form.controls['email'].touched)"
                      class="alert alert-danger">

            <div *ngIf="form.controls['email'].errors">
              Une addresse email est requise.
            </div>
          </div>
        </div>
        <!-- Checkbox + button  -->
        <div class="form-check-button">
          <!-- Checkbox  -->
          <div class="check form-check">
            <input type="checkbox"
                   class="form-check-input iclbr-checkbox"
                   id="checkbox"
                   formControlName="cgu">
            <label class="form-check-label" for="checkbox">
              J'accepte les conditions d'utilisations
            </label>
          </div>
          <!-- Button  -->
          <div class="button">
            <button type="submit"
                    class="iclbr-button iclbr-primary-background">
              Envoyer
            </button>
          </div>
        </div>
        <!-- Error CGU  -->
        <div *ngIf="form.controls['cgu'].invalid
                      && (form.controls['cgu'].dirty
                      || form.controls['cgu'].touched)"
             class="alert alert-danger">

          <div *ngIf="form.controls['cgu'].errors?.['required']
                      || form.controls['cgu'].errors?.['requiredTrue']">
            Vous devez accepter le contrat d'utilisation.
          </div>
        </div>
        <!-- Invalid form  -->
        <div *ngIf="showMessageErrorForm && form.invalid" class="invalid-form alert alert-danger">
          <div>
            Le fomulaire n'est pas valide. Vous devez mettre une adresse mail et accepter le
            contrtat d'utilisation.
          </div>
        </div>
        <!--  Spinner  -->
        <div *ngIf="loading" class="loader">
          <app-spinner></app-spinner>
        </div>
      </form>
      <!--  Error or success   -->
      <div *ngIf="error || success" class="response">
        <ngb-alert *ngIf="error" type="danger">
          Oups ! Une erreur est survenue lors de la sauvegarde de
          l'adresse mail.
        </ngb-alert>
        <ngb-alert *ngIf="success" type="success">
          Merci ! Nous avons bien pris en compte votre adresse email !
          Nous vous recontacterons dès lors que l'application sera terminée !
        </ngb-alert>
      </div>
    </div>
  </div>
</div>
