import {IRootState} from '../../../models/ngrx/IRootState';
import {IUser} from "../../../models/ngrx/IUser";


export const userState: IUser = {
  firstName : '',
  lastName : '',
  email : ''
};

export function setUser(state: IRootState, props: any): IRootState {

  return {
    ...state,
    user: {
      ...state.user,
      firstName : props.data.firstName,
      lastName : props.data.lastName,
      email : props.data.email
    },
  };
}
