import {Component, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {EmailService} from "../../../service/firebase/email.service";
import {RegexEnum} from "../../../enums/Regex";

@Component({
  selector: 'app-modal-save-email',
  templateUrl: './modal-save-email.component.html',
  styleUrls: [
    './styles/modal-save-email.mobile.scss',
    './styles/modal-save-email.tabs.scss',
    './styles/modal-save-email.desktop.scss',
  ]
})
export class ModalSaveEmailComponent implements OnInit {
  @Output() close = new EventEmitter<string>();

  @HostListener('document:click', ['$event'])
  clickout(event : any) {
    if(event.target.className === 'overlay') {
      this.closeModal()
    }
  }

  form = this.fb.group({
    email: [null, [Validators.required, Validators.email, Validators.pattern(RegexEnum.EMAIL)]],
    cgu: [false, [Validators.required, Validators.requiredTrue]]
  })
  loading = false;
  error = false;
  success = false;
  showMessageErrorForm = false;
  constructor(private fb : FormBuilder,
              private emailService : EmailService,
              private eRef: ElementRef) {

  }

  ngOnInit(): void {
  }

  onSubmit() : void {
   if(!this.form.invalid){
     this.showMessageErrorForm = false
     this.loading = true
     this.emailService.add(this.form.value.email!)
       .then((res) => {
         this.loading = false
         if(res){
           this.success = true
           setTimeout(() => {
             this.success = false
             this.closeModal();
           }, 5000)
         }else{
           this.error = true
         }
       })
       .catch((res) => {
         this.loading = false
         this.error = true
       })
   }else{
     this.showMessageErrorForm = true
   }
  }

  closeModal(): void {
    this.close.emit();
  }

}
